import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

import Logo from "../images/logos/logo.png"

const Terms = () => {
  return (
    <Layout
      logo={Logo}
      header="our terms policy"
      btn="download pdf"
      link="#download"
    >
      <Seo title="Inspect Live - Website Terms" />

      {/* INTRO SECTION */}
      <div className="max-w-full terms px-8 sm:px-12 lg:px-24 pt-8 text-sm font-light bg-base-200 text-blueLight">
        <p className="text-2xl font-semibold underline">Terms & Conditions</p>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
          <div className="mt-1 text-sm font-light text-blueHighlight sm:mt-0 sm:col-span-2">
            <div className="mt-2 space-y-4">
              <p className="font-semibold text-md">
                In this Agreement the following terms have the following
                meanings:
              </p>
              <ol>
                <li className="mt-4">
                  <span className="font-semibold">Definitions</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Agreement” means the agreement between the Real Estate
                        Institute’s member named on the front sheet and the Real
                        Estate Institute once accepted by the Real Estate
                        Institute, the Terms and Conditions, the Real Estate
                        Institute standard price list on its website and if
                        applicable, any Special Conditions, and any further
                        Conditions that the Real Estate Institute may publish
                        from time to time and provide to the Real Estate
                        Institute’s Member respecting the Services;
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “End User” means each person in the Office authorised by
                        the Real Estate Institute’s Member to use REI Forms Live
                        Where appropriate, this term includes the employees,
                        servants and agents of the Real Estate Institute’s
                        Member who have access to the Services. End Users may
                        only use the Services for the business purpose of the
                        Real Estate Institute’s Member.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Office” means the single office location at which the
                        subscription for REI Forms Live will apply.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Price” means the price shown on the front of this form,
                        as amended from time to time by the Real Estate
                        Institutes on the REI Forms Live and REI Inspect Live
                        websites.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Privacy Legislation” means the Privacy Act 1988 (Cth)
                        (the Act) and any State privacy legislation which may be
                        enacted during the term of the Agreement.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Real Estate Institutes” means the Real Estate Institute
                        of the members’ state .
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Real Estate Institutes Member” means the company or
                        person whose name and address appears on the front of
                        this form;
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Services” means access to REI Forms Live and if the
                        Real Estate Institute’s Member has subscribed to REI
                        Inspect Live then that service as well.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        “Usage Fee” means the fee charged by the Real Estate
                        Institutes from time to time for the Office of a Real
                        Estate Institute’s Member to download documents and
                        forms through REI Forms Live.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span className="font-semibold">
                    Purpose of the Agreement
                  </span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institute’s Member hereby requests the
                        Real Estate Institutes to make the Services available to
                        the Real Estate Institute’s Member at the Office.
                        Acceptance of the Agreement by the Real Estate
                        Institutes entitles the Real Estate Institute’s Member
                        to access the Services the subject to this Agreement at
                        the Office.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The website, reiformslive.com.au, is owned and operated
                        by Dynamic Methods Pty Ltd. The Services are located on
                        that website. The Real Estate Institutes Member’s access
                        to the website is conditional upon acceptance and
                        compliance with the Terms and Conditions and this
                        Agreement. The Real Estate Institutes Member’s use of,
                        and access to, the Services on the website constitutes
                        the Real Estate Institutes Member’s agreement to this
                        Agreement. The Real Estate Institutes reserve the right
                        to amend the Agreement at any time. Since the Real
                        Estate Institute’s Member is bound by the Agreement, the
                        Real Estate Institute’s Member should periodically refer
                        to this document on the website.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Terms of Agreement</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        All information, whether printed, written or oral,
                        incorporated in the publications or in any other
                        document delivered to the Real Estate Institute’s Member
                        pursuant to this Agreement or submitted in answer to a
                        regular or special enquiry or voluntarily furnished to
                        the Real Estate Institute’s Member by Real Estate
                        Institute is for the use of the Real Estate Institute’s
                        Member as one factor in the Real Estate Institutes
                        Member’s business.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        This Agreement comes into effect immediately upon the
                        Real Estate Institutes notifying the Real Estate
                        Institute’s Member that it will provide the Services to
                        the Real Estate Institute’s Member and remains in effect
                        until terminated as set out in this Agreement.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Payments &amp; Charges</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institute’s Member will pay the Real
                        Estate Institutes the Price and Usage Fee.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Price and Usage Fee will commence on the Real Estate
                        Institute’s Members acceptance of this Agreement and are
                        payable in advance by Credit Card.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Services</span>
                  <p className="mt-4 pl-6">
                    The Real Estate Institutes reserve the right to add or
                    withdraw any of its Services, and modify or otherwise
                    improve the Services without notice. The Real Estate
                    Institutes reserves the right to change any and all Prices,
                    Usage Fees or other conditions of supply of this Agreement
                    or the Services at any time and all times. However, the Real
                    Estate Institutes will use its best efforts to provide
                    thirty (30) days notice in advance of the effective date of
                    such changes. Continued use by the Real Estate Institute’s
                    Member of the Services is acceptance of any changes.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Copyright</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        It is recognised that all data and information provided
                        through the Real Estate Institutes and its formats, will
                        be subject to the copyright and other property rights of
                        the Real Estate Institutes or the respective database
                        provider. The Real Estate Institute’s Member must not
                        commit or permit any act or omission by it or its
                        agents, employees or any third party which will impair
                        the copyright or other
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institute’s Member must not disclose,
                        transfer, duplicate, reproduce, retain for a purpose
                        other than that for which it was first acquired, sell or
                        reuse any information supplied by the Real Estate
                        Institutes regardless of the form in which that
                        information was supplied and, in particular, without
                        limiting the generality must not do any of those acts
                        with respect to information contained in hardcopy or any
                        storage media, of whatever type, nature and description.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institute’s Member must not disclose,
                        transfer, duplicate, reproduce, retain for a purpose
                        other than that for which it was first acquired, sell or
                        reuse any information supplied by the Real Estate
                        Institutes regardless of the form in which that
                        information was supplied and, in particular, without
                        limiting the generality must not do any of those acts
                        with respect to information contained in hardcopy or any
                        storage media, of whatever type, nature and description.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        Without limiting the generality of the sub-clause 6(b),
                        no information supplied by the Real Estate Institutes
                        will be copied or transferred to another file, where
                        access to the Services permits the Real Estate
                        Institute’s Member to print data in machine readable
                        form (using commands designated for this purpose) and
                        the Real Estate Institute’s Member agrees:
                      </span>

                      <ol className="mt-4 pl-6">
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Not to transfer, retransmit, duplicate or resell any
                            items from the database to any third party without
                            prior written approval of the Real Estate
                            Institutes;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Not to use these items from the Services to combine
                            with any other information with the object of
                            offering it on-line or in any other way to third
                            parties without prior written approval of the Real
                            Estate Institutes.
                          </span>
                        </li>
                      </ol>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The intellectual property rights (including copyright)
                        in all information, text, material, graphics, software,
                        source code and advertisements on the website
                        (“Content”) are owned and/or controlled by the Real
                        Estate Institutes, its suppliers and/or licensors unless
                        expressly indicated otherwise on the website. The
                        Content is protected by Australian and international
                        copyright and trademark laws. The Real Estate
                        Institute’s Member must not modify, copy, reproduce,
                        republish, frame, upload to a third party, post,
                        transmit or distribute this Content in any way except as
                        expressly provided for on the website or expressly
                        authorised in writing by the Real Estate Institutes or
                        the relevant third party owner or rights holder. The
                        Real Estate Institutes Member must not use the website
                        in any manner or for any purpose which is unlawful or in
                        any manner which violates any rights of the Real Estate
                        Institutes or any other party or which is prohibited by
                        this Agreement. Nothing contained on the website should
                        be construed as granting any license or right of use of
                        any trademark displayed on the website without the
                        express written permission of the Real Estate
                        Institutes, the relevant third-party owner or rights
                        holder.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        Copyright in all Real Estate Institutes information and
                        products (including precedents, data, pages, documents,
                        maps, online graphics, images, web pages, audio and
                        video) is vested in the Real Estate Institutes and is
                        protected by the Copyright Act 1968. Apart from any use
                        as permitted under the fair dealing provisions of the
                        Copyright Act 1968, all other rights are reserved, and
                        no information and products or part of them may be
                        reproduced by any process, distributed, commercialised
                        or reused for any other purpose without the prior
                        written permission of the Real Estate Institutes.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Disclaimer</span>
                  <p className="mt-4 pl-6">
                    These Services and their content are made available on the
                    understanding that the Real Estate Institutes is not
                    rendering professional advice. While the Real Estate
                    Institutes have made every effort to ensure the accuracy,
                    reliability, completeness and suitability for purpose of the
                    information presented, the Real Estate Institutes do not
                    give any guarantee or take any responsibility or accept any
                    liability (including without limitation, liability in
                    negligence) arising from or connected to any errors or
                    omissions. The Real Estate Institutes accept no
                    responsibility and disclaims all liability for any losses,
                    damages or costs as a result of the use or reliance on the
                    Service.
                  </p>
                  <p className="mt-4 pl-6">
                    It is strongly recommended that End Users and Real Estate
                    Institute’s Members exercise their own skill and care with
                    respect to the use of the information, and before relying on
                    the information that End Users and Real Estate Institute’s
                    Members should carefully consider its relevance to their
                    purpose and obtain any professional advice appropriate to
                    their particular circumstances. The Services may be subject
                    to Privacy Legislation and contractual restrictions and must
                    not be used for direct marketing of goods and services or be
                    released to any person or third party for the purpose of
                    direct marketing of goods and services. The Real Estate
                    Institutes take no responsibility for any breaches of
                    Privacy Legislation by any person in relation to the
                    Services.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Warranty</span>
                  <p className="mt-4 pl-6">
                    All terms, conditions, representations, warranties,
                    guarantees and indemnities (“Representations”), not
                    expressly contained in this Agreement, whether arising by
                    operation of law or otherwise, are hereby expressly excluded
                    to the maximum extent permitted by law and, in particular,
                    but in no way limiting the generality of it, the Real Estate
                    Institutes makes no Representations as to the completeness
                    or accuracy of the data comprised in the Services or as to
                    its fitness for use for any purpose.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Liability</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        Real Estate Institutes will not be liable to the Real
                        Estate Institute’s Member for any loss of profit, or
                        earning, any damage or goodwill suffered by any person
                        arising directly or indirectly out of its provision of
                        the Services whether in accordance with the terms of the
                        Agreement or otherwise and whether caused by negligence
                        or wilful act or omission of the Real Estate Institutes,
                        its servants or agents or from any other cause.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        Under no circumstances will the Real Estate Institutes
                        or any related corporation or their respective board
                        members, officers, or employees be liable for any
                        indirect, incidental, special and/or consequential
                        damages or loss of profits whatsoever which result from
                        any use or access of, or any inability to use or access,
                        the website or any Content.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institutes’ liability of any nature or
                        kind including, without limitation, negligence with
                        respect to the subject of this Agreement, or for
                        non-compliance with or breach of any Representations
                        which cannot be excluded by Agreement, is limited to
                        providing the Services in respect of which the breach or
                        non-compliance arose, or, at the Real Estate Institutes
                        option, to the cost of having that Service provided.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Indemnity</span>
                  <p className="mt-4 pl-6">
                    The Real Estate Institute’s Member indemnifies and holds the
                    Real Estate Institutes and its board members, officers and
                    employees harmless from and against any loss, claim, demand,
                    expense (including legal fees) arising out of the Real
                    Estate Institutes’ Member’s use of the Services. The
                    obligations imposed by this clause benefit the Real Estate
                    Institutes and its employees and board members jointly and
                    severally.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Default</span>
                  <p className="mt-4 pl-6">
                    If there is a breach of any of the Terms and Conditions of
                    this Agreement and the Real Estate Institutes have given 7
                    days notice of breach then:
                  </p>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        All monies payable by the Real Estate Institute’s Member
                        to the Real Estate Institutes will at the Real Estate
                        Institutes election become immediately due and payable
                        despite the specified period for payment not yet having
                        expired;
                      </span>
                    </li>

                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        Any credit facilities may be withdrawn by the Real
                        Estate Institutes;
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institutes may withhold the delivery of
                        Services already ordered by the Real Estate Institute’s
                        Member and is at liberty to suspend the Services to be
                        provided to the Real Estate Institute’s Member pursuant
                        to this Agreement should the Real Estate Institute’s
                        Member default on any term of this Agreement;
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        In the event of a suspension of a Real Estate
                        Institute’s Members’ account the Real Estate Institutes
                        are not liable to refund any monies or credit the Real
                        Estate Institute’s Member’s account with respect to the
                        time the Real Estate Institute’s Member remains in
                        breach of the Agreement for the Services that ought to
                        have been provided had the Real Estate Institute’s
                        Member not been in breach of this Agreement;
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institutes may list the Real Estate
                        Institutes Member with any credit rating authority it so
                        chooses and will only remove such a listing when the
                        Real Estate Institute’s Member remedies its breach of
                        this Agreement;
                      </span>
                    </li>

                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institutes may charge to the Real Estate
                        Institute’s Member all monies, costs, charges and
                        expenses (including legal costs on an indemnity basis)
                        of any attempt made by or on behalf of the Real Estate
                        Institutes to recover any monies owing by the Real
                        Estate Institute’s Member to the Real Estate Institutes
                        or to secure any indebtedness or liability by the Real
                        Estate Institute’s Member to the Real Estate Institutes
                        despite no demand having been made by the Real Estate
                        Institutes for payment to the Real Estate Institute’s
                        Member.
                      </span>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">Entire Agreement</span>
                  <p className="mt-4 pl-6">
                    This Agreement, including notices of other Conditions
                    provided to the Real Estate Institute’s Member in any
                    printed form or otherwise published by the Real Estate
                    Institutes, including revisions of them constitute the
                    entire agreement between the parties on this subject matter.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Termination</span>
                  <ol className="mt-4 pl-6">
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        The Real Estate Institutes may, in its absolute
                        discretion, terminate this Agreement:
                      </span>
                      <ol className="mt-4 pl-6">
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Immediately following cancellation, resignation or
                            termination of Your Real Estate Institutes’
                            membership;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Immediately if You cease to be licensed under the
                            Property, Stock and Business Agents Act 2002 or any
                            legislation which succeeds that act;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Immediately if You breach any of the terms of this
                            Agreement. The Real Estate Institutes may elect to
                            offer You the opportunity to rectify any breach upon
                            such terms and conditions as Real Estate Institutes
                            may in its absolute discretion determine;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            If You are a corporation, You become the subject of
                            any insolvency proceedings or if a manager or
                            receiver is appointed in respect of Your business;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            If You are a firm or partnership, You are dissolved;
                            or
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            If You are an individual, You become bankrupt.
                          </span>
                        </li>
                      </ol>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        In addition to the rights in clause 5.1, Real Estate
                        Institutes may terminate this Agreement at any time
                        without cause by giving You not less than 30 Business
                        Days notice in writing or via the Product.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        You may terminate this Agreement at any time without
                        cause by giving not less than 30 Business Days notice in
                        writing to Real Estate Institutes.
                      </span>
                    </li>
                    <li className="pr-4 flex text-sm">
                      <span className="pl-2">
                        If this Agreement is terminated:
                      </span>
                      <ol className="mt-4 pl-6">
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Your right to use the Product is immediately revoked
                            from the time of termination of the Agreement and
                            Real Estate Institutes will immediately suspend Your
                            access to the Product;
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            You must pay any debit balance of Your account and
                            pay Real Estate Institutes any outstanding Fees or
                            charges for services Real Estate Institutes has
                            provided and any expenses Real Estate Institutes may
                            have incurred up to the date of termination; and
                          </span>
                        </li>
                        <li className="pr-4 flex text-sm">
                          <span className="pl-2">
                            Real Estate Institutes is not liable to pay any
                            refund of Subscription Fees or unused amounts in
                            credit in Your account at the time of termination.
                          </span>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <span className="font-semibold">
                    Real Estate Institute’s Member Privacy Statement
                  </span>
                  <p className="mt-4 pl-6">
                    The Real Estate Institutes Member warrants with respect to
                    the information supplied that all requirements of the
                    Privacy Legislation as to content security, access,
                    disclosure and use, have or will be complied with in full.
                    The Real Estate Institute’s Member will comply with any
                    reasonable direction relating to privacy given by Real
                    Estate Institutes.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">
                    Real Estate Institutes Privacy Statement
                  </span>
                  <p className="mt-4 pl-6">
                    The Real Estate Institutes is bound by the Privacy
                    Legislation. Please refer to the Real Estate Institutes
                    Privacy Policy on their website.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Security</span>
                  <p className="mt-4 pl-6">
                    The Real Estate Institute’s Member agrees to do whatever is
                    reasonably requested by Real Estate Institutes to maintain
                    security for the Services and to ensure that the Services
                    are not used by people who are not permitted to use it under
                    this Agreement.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Law</span>
                  <p className="mt-4 pl-6">
                    This Agreement and all matters arising from the relationship
                    of the Real Estate Institutes and the Real Estate
                    Institute’s Member will be interpreted and governed in
                    accordance with the laws of the State of the Real Estate
                    Institute . The Real Estate Institutes Member submits to the
                    exclusive jurisdiction of the Courts of their state.
                  </p>
                </li>

                <li>
                  <span className="font-semibold">Special Condition</span>
                  <p className="mt-4 pl-6">
                    The special conditions set out on the front sheet apply to
                    this Agreement. In the case of a conflict of provisions, the
                    special conditions prevail.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div id="download"></div>
    </Layout>
  )
}

export default Terms
